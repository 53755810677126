import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/auth-guard.component';
import Layout from './components/layout/layout.component';
import DocsLayout from './components/layout-docs/docs-layout.component';
import GuestGuard from './components/guest-guard.component';
import LoadingScreen from './components/loading-screen.component';
import { ROLES, ROLE_ACCESS } from './constants';

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Docs pages
const Docs = Loadable(lazy(() => import('./pages/docs.page')));

// Public
const ConnectEmail = Loadable(lazy(() => import('./pages/connect-email.page')));
const ConnectMobile = Loadable(
  lazy(() => import('./pages/connect-mobile.page')),
);
const FortnoxLanding = Loadable(
  lazy(() => import('./pages/fortnox-landing.page')),
);
const FortnoxCreate = Loadable(
  lazy(() => import('./pages/fortnox-create.page')),
);
const EducationCourseInstance = Loadable(
  lazy(() => import('./pages/education-course-instance.page')),
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/login.page')));
const Register = Loadable(lazy(() => import('./pages/register.page')));
const ResetPassword = Loadable(
  lazy(() => import('./pages/reset-password.page')),
);
const CreateUser = Loadable(lazy(() => import('./pages/create-user.page')));
const CreateEmploymentUser = Loadable(
  lazy(() => import('./pages/create-employment-user.page')),
);
const CreatePassword = Loadable(
  lazy(() => import('./pages/create-password.page')),
);

// User pages
const UserDashboard = Loadable(
  lazy(() => import('./pages/user-dashboard.page')),
);
const UserCompetences = Loadable(
  lazy(() => import('./pages/user-competences.page')),
);
const UserCertificates = Loadable(
  lazy(() => import('./pages/user-certificates.page')),
);
const UserCertificatesEmployment = Loadable(
  lazy(() => import('./pages/user-certificates-employment.page')),
);
const UserCompanyCertificates = Loadable(
  lazy(() => import('./pages/user-company-certificates.page')),
);
const UserEmployments = Loadable(
  lazy(() => import('./pages/user-employments.page')),
);
const UserEmployment = Loadable(
  lazy(() => import('./pages/user-employment.page')),
);
const UserRequirement = Loadable(
  lazy(() => import('./pages/user-requirement.page')),
);
const UserAccount = Loadable(lazy(() => import('./pages/user-account.page')));
const UserPermissions = Loadable(
  lazy(() => import('./pages/user-permissions.page')),
);
const UserPermission = Loadable(
  lazy(() => import('./pages/user-permission.page')),
);

// Company pages
const CompanyFiles = Loadable(lazy(() => import('./pages/company-files.page')));
const EmployeeCertificates = Loadable(
  lazy(() => import('./pages/employee-certificates.page')),
);
const EmployeeCompanyCertificates = Loadable(
  lazy(() => import('./pages/employee-company-certificates.page')),
);
const CompanyDashboard = Loadable(
  lazy(() => import('./pages/company-dashboard.page')),
);
const CompanyEmployments = Loadable(
  lazy(() => import('./pages/company-employments.page')),
);
const CompanyEmployment = Loadable(
  lazy(() => import('./pages/company-employment.page')),
);
const CompanyEmploymentCreate = Loadable(
  lazy(() => import('./pages/company-employment-create.page')),
);
const CompanyEmploymentRequirement = Loadable(
  lazy(() => import('./pages/company-employment-requirement.page')),
);
const CompanyOrganisation = Loadable(
  lazy(() => import('./pages/company-organisation.page')),
);
const CompanyLibrary = Loadable(
  lazy(() => import('./pages/company-library.page')),
);
const CompanyCompetence = Loadable(
  lazy(() => import('./pages/company-competence.page')),
);
const CompanyCompetenceCreate = Loadable(
  lazy(() => import('./pages/company-competence-create.page')),
);
const CompanyCompetenceEdit = Loadable(
  lazy(() => import('./pages/company-competence-edit.page')),
);
const CompanyPermissions = Loadable(
  lazy(() => import('./pages/company-permissions.page')),
);
const CompanyPermission = Loadable(
  lazy(() => import('./pages/company-permission.page')),
);
const CompanyPermissionCreate = Loadable(
  lazy(() => import('./pages/company-permission-create.page')),
);
const CompanyTags = Loadable(lazy(() => import('./pages/company-tags.page')));
const CompanyTagCreate = Loadable(
  lazy(() => import('./pages/company-tag-create.page')),
);
const CompanyTagUpdate = Loadable(
  lazy(() => import('./pages/company-tag-update.page')),
);
const CompanyManagers = Loadable(
  lazy(() => import('./pages/company-managers.page')),
);
const CompanyManagerCreate = Loadable(
  lazy(() => import('./pages/company-manager-create.page')),
);
const CompanyManagerUpdate = Loadable(
  lazy(() => import('./pages/company-manager-update.page')),
);
const CompanyExecutives = Loadable(
  lazy(() => import('./pages/company-executives.page')),
);
const CompanyExecutiveCreate = Loadable(
  lazy(() => import('./pages/company-executive-create.page')),
);
const CompanyExecutiveUpdate = Loadable(
  lazy(() => import('./pages/company-executive-update.page')),
);
const CompanyPermissionsReport = Loadable(
  lazy(() => import('./pages/company-permissions-report.page')),
);
const CompanyPermissionsMissing = Loadable(
  lazy(() => import('./pages/company-permissions-missing.page')),
);
const CompanyPermissionsAssign = Loadable(
  lazy(() => import('./pages/company-permissions-assign.page')),
);
const CompanyRequirementsReport = Loadable(
  lazy(
    () => import('./pages/company-reports.page/company-requirements-report'),
  ),
);
const CompanyLibraryReport = Loadable(
  lazy(
    () => import('./pages/company-report-library.page/company-library-report'),
  ),
);
const CompanyEmploymentsReport = Loadable(
  lazy(
    () =>
      import('./pages/company-reports.page/company-global-competences-report'),
  ),
);
const CompanySettings = Loadable(
  lazy(() => import('./pages/company-settings.page/general')),
);
const CompanyApiConnections = Loadable(
  lazy(() => import('./pages/company-settings.page/api-connections')),
);
const CompanyReminders = Loadable(
  lazy(() => import('./pages/company-settings.page/reminders')),
);
const CompanyExpiringWarning = Loadable(
  lazy(() => import('./pages/company-settings.page/expiring-warning')),
);

const CompanyCourseInstances = Loadable(
  lazy(() => import('./pages/company-course-instances.page')),
);
const CompanyCourseInstanceCreate = Loadable(
  lazy(() => import('./pages/company-course-instance-create.page')),
);
const CompanyCourseInstanceEdit = Loadable(
  lazy(() => import('./pages/company-course-instance-edit.page')),
);

// Reseller pages
const ResellerCompanies = Loadable(
  lazy(() => import('./pages/reseller-companies.page')),
);

// Admin pages
const AdminCompanyEdit = Loadable(
  lazy(() => import('./pages/admin-company-edit.page')),
);
const AdminCompanyCreate = Loadable(
  lazy(() => import('./pages/admin-company-create.page')),
);
const AdminID06Competences = Loadable(
  lazy(() => import('./pages/admin-id06-competences.page')),
);
const AdminCompetences = Loadable(
  lazy(() => import('./pages/admin-competences.page')),
);
const AdminCompetenceEdit = Loadable(
  lazy(() => import('./pages/admin-competence-edit.page')),
);
const AdminCompetenceCreate = Loadable(
  lazy(() => import('./pages/admin-competence-create.page')),
);
const AdminCompanyAddApiConnection = Loadable(
  lazy(() => import('./pages/admin-company-add-api-connection.page')),
);
const AdminCompanies = Loadable(
  lazy(() => import('./pages/admin-companies.page')),
);
const AdminResellerCreate = Loadable(
  lazy(() => import('./pages/admin-reseller-create.page')),
);
const AdminResellerEdit = Loadable(
  lazy(() => import('./pages/admin-reseller-edit.page')),
);
const AdminResellers = Loadable(
  lazy(() => import('./pages/admin-resellers.page')),
);
const AdminUsers = Loadable(lazy(() => import('./pages/admin-users.page')));
const AdminUser = Loadable(lazy(() => import('./pages/admin-user.page')));
const AdminMachineTypes = Loadable(
  lazy(() => import('./pages/admin-machine-types.page')),
);
const AdminMachineType = Loadable(
  lazy(() => import('./pages/admin-machine-type.page')),
);
const AdminInvoices = Loadable(
  lazy(() => import('./pages/admin-invoices.page')),
);
const AdminInvoice = Loadable(lazy(() => import('./pages/admin-invoice.page')));
const AdminInvoiceCompany = Loadable(
  lazy(() => import('./pages/admin-invoice-company.page')),
);
const AdminCalculateCost = Loadable(
  lazy(() => import('./pages/admin-calculate-cost.page')),
);
const AdminNewsCreate = Loadable(
  lazy(() => import('./pages/admin-news-create.page')),
);
const AdminNewsEdit = Loadable(
  lazy(() => import('./pages/admin-news-edit.page')),
);
const AdminImportEmployments = Loadable(
  lazy(() => import('./pages/admin-import-employments.page')),
);
const AdminImportCertificates = Loadable(
  lazy(() => import('./pages/admin-import-certificates.page')),
);

// Common pages
const AddCompetences = Loadable(
  lazy(() => import('./pages/add-competences.page')),
);
const Competence = Loadable(lazy(() => import('./pages/competence.page')));
const NewsPosts = Loadable(lazy(() => import('./pages/news-posts.page')));
const NewsPost = Loadable(lazy(() => import('./pages/news-post.page')));
const SsoLogin = Loadable(lazy(() => import('./pages/sso-login.page')));
const SsoLogout = Loadable(lazy(() => import('./pages/sso-logout.page')));
const SsoCreateUser = Loadable(lazy(() => import('./pages/sso-create-user.page')),);
const SsoConnectUser = Loadable(lazy(() => import('./pages/sso-connect-user.page')),);
const NotFound = Loadable(lazy(() => import('./pages/not-found.page')));

export const getRoutes = (user: any, company: any) => {
  let managerCanSeeLibrary = false;
  let managerCanSeeOrganisations = false;
  if (company && company.role.role === ROLES.COMPANY_MANAGER) {
    if (
      company.role.access.find(
        (x: any) => x === ROLE_ACCESS.ADMINISTRATE_LIBRARY,
      )
    ) {
      managerCanSeeLibrary = true;
    }

    if (
      company.role.access.find(
        (x: any) => x === ROLE_ACCESS.ADMINISTRATE_ORGANISATIONS,
      )
    ) {
      managerCanSeeOrganisations = true;
    }
  }

  const privateRoutes = [
    {
      path: 'news',
      element: <NewsPosts />,
    },
    {
      path: 'news/:newsPostId',
      element: <NewsPost />,
    },
    {
      path: 'competence/:competenceId',
      element: <Competence />,
    },
    {
      path: 'employee/:employeeId/certificates/:competenceId',
      element: <EmployeeCertificates />,
    },
    {
      path: 'employee/:employeeId/company-certificates/:companyCompetenceId',
      element: <EmployeeCompanyCertificates />,
    },
    {
      path: '',
      element: <UserDashboard />,
    },
    {
      path: 'competences/*',
      element: <UserCompetences />,
    },
    {
      path: 'certificates/:competenceId',
      element: <UserCertificates />,
    },
    {
      path: 'certificates/:competenceId/employment/:companyId',
      element: <UserCertificatesEmployment />,
    },
    {
      path: 'company-certificates/:companyCompetenceId/employment/:companyId',
      element: <UserCompanyCertificates />,
    },
    {
      path: 'add-certificates',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/competence/:competenceId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/company-competence/:companyCompetenceId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/competence/:competenceId/task/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/competence/:competenceId/grading/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/competence/:competenceId/certificate',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/company-competence/:companyCompetenceId/task/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/company-competence/:companyCompetenceId/grading/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/company-competence/:companyCompetenceId/certificate',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/competence/:competenceId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/company-competence/:companyCompetenceId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/competence/:competenceId/task/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/competence/:competenceId/grading/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/competence/:competenceId/certificate',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/company-competence/:companyCompetenceId/task/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/company-competence/:companyCompetenceId/grading/:verificationId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employee/:employeeId/company-competence/:companyCompetenceId/certificate',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employees/:employeeIds',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employees/:employeeIds/competence/:competenceId',
      element: <AddCompetences />,
    },
    {
      path: 'add-certificates/employees/:employeeIds/company-competence/:companyCompetenceId',
      element: <AddCompetences />,
    },
    {
      path: 'employments',
      element: <UserEmployments />,
    },
    {
      path: 'employment/:companyId',
      element: <UserEmployment />,
    },
    {
      path: 'employment/:companyId/requirement/:organisationId',
      element: <UserRequirement />,
    },
    {
      path: 'permissions',
      element: <UserPermissions />,
    },
    {
      path: 'permission/:permissionId',
      element: <UserPermission />,
    },
    {
      path: 'account',
      element: <UserAccount />,
    },
    {
      path: 'company-competence/:companyCompetenceId',
      element: <CompanyCompetence />,
    },
  ];

  if (user && user.roles.includes(ROLES.RESELLER_ADMIN)) {
    privateRoutes.push({
      path: 'reseller-companies',
      element: <ResellerCompanies />,
    });
    privateRoutes.push({
      path: 'reseller-company/:companyId',
      element: <AdminCompanyEdit />,
    });
    privateRoutes.push({
      path: 'reseller-add-company',
      element: <AdminCompanyCreate />,
    });
  }

  if (user && user.roles.includes(ROLES.SUPER_ADMIN)) {
    privateRoutes.push({
      path: 'admin-id06-competences',
      element: <AdminID06Competences />,
    });
    privateRoutes.push({
      path: 'admin-competences',
      element: <AdminCompetences />,
    });
    privateRoutes.push({
      path: 'admin-competence/:competenceId',
      element: <AdminCompetenceEdit />,
    });
    privateRoutes.push({
      path: 'admin-add-competence',
      element: <AdminCompetenceCreate />,
    });
    privateRoutes.push({
      path: 'admin-company/:companyId',
      element: <AdminCompanyEdit />,
    });
    privateRoutes.push({
      path: 'admin-company/:companyId/add-api-connection',
      element: <AdminCompanyAddApiConnection />,
    });
    privateRoutes.push({
      path: 'admin-companies',
      element: <AdminCompanies />,
    });
    privateRoutes.push({
      path: 'admin-add-company',
      element: <AdminCompanyCreate />,
    });
    privateRoutes.push({
      path: 'admin-reseller/:resellerId',
      element: <AdminResellerEdit />,
    });
    privateRoutes.push({
      path: 'admin-resellers',
      element: <AdminResellers />,
    });
    privateRoutes.push({
      path: 'admin-add-reseller',
      element: <AdminResellerCreate />,
    });
    privateRoutes.push({
      path: 'admin-users',
      element: <AdminUsers />,
    });
    privateRoutes.push({
      path: 'admin-user/:userId',
      element: <AdminUser />,
    });
    privateRoutes.push({
      path: 'admin-machine-types',
      element: <AdminMachineTypes />,
    });
    privateRoutes.push({
      path: 'admin-machine-type/:machineTypeId',
      element: <AdminMachineType />,
    });
    privateRoutes.push({
      path: 'admin-invoices',
      element: <AdminInvoices />,
    });
    privateRoutes.push({
      path: 'admin-invoice/:id',
      element: <AdminInvoice />,
    });
    privateRoutes.push({
      path: 'admin-invoice-company/:id',
      element: <AdminInvoiceCompany />,
    });
    privateRoutes.push({
      path: 'admin-calculate-cost',
      element: <AdminCalculateCost />,
    });
    privateRoutes.push({
      path: 'admin-news-create',
      element: <AdminNewsCreate />,
    });
    privateRoutes.push({
      path: 'admin-news-edit/:newsPostId',
      element: <AdminNewsEdit />,
    });
  }

  if (
    user &&
    (user.roles.includes(ROLES.SUPER_ADMIN) ||
      user.roles.includes(ROLES.RESELLER_ADMIN))
  ) {
    privateRoutes.push({
      path: 'import-employees',
      element: <AdminImportEmployments />,
    });
    privateRoutes.push({
      path: 'import-certificates',
      element: <AdminImportCertificates />,
    });
  }

  if (
    company &&
    (company.role.role === ROLES.SUPER_ADMIN ||
      company.role.role === ROLES.RESELLER_ADMIN ||
      company.role.role === ROLES.COMPANY_MANAGER ||
      company.role.role === ROLES.COMPANY_ADMIN ||
      company.role.role === ROLES.COMPANY_EXECUTIVE)
  ) {
    privateRoutes.push({
      path: 'company-dashboard',
      element: <CompanyDashboard />,
    });
    privateRoutes.push({
      path: 'company-employments',
      element: <CompanyEmployments />,
    });
    privateRoutes.push({
      path: 'company-employment/:employeeId/*',
      element: <CompanyEmployment />,
    });
    privateRoutes.push({
      path: 'company-employment/:employeeId/requirement/:organisationId',
      element: <CompanyEmploymentRequirement />,
    });
    privateRoutes.push({
      path: 'company-permissions',
      element: <CompanyPermissions />,
    });
    privateRoutes.push({
      path: 'company-permission/:permissionId',
      element: <CompanyPermission />,
    });
    privateRoutes.push({
      path: 'company-create-permission',
      element: <CompanyPermissionCreate />,
    });
    privateRoutes.push({
      path: 'company-create-permission/:permissionId',
      element: <CompanyPermissionCreate />,
    });
    privateRoutes.push({
      path: 'company-permissions/missing',
      element: <CompanyPermissionsMissing />,
    });
    privateRoutes.push({
      path: 'company-permissions/assign/:competenceId',
      element: <CompanyPermissionsAssign />,
    });
    privateRoutes.push({
      path: 'company-reports/requirements/*',
      element: <CompanyRequirementsReport />,
    });
    privateRoutes.push({
      path: 'company-reports/library/*',
      element: <CompanyLibraryReport />,
    });
    privateRoutes.push({
      path: 'company-reports/others/*',
      element: <CompanyEmploymentsReport />,
    });
    privateRoutes.push({
      path: 'company-reports/permissions/*',
      element: <CompanyPermissionsReport />,
    });
    privateRoutes.push({
      path: 'company-course-instances',
      element: <CompanyCourseInstances />,
    });
    privateRoutes.push({
      path: 'company-create-course-instance',
      element: <CompanyCourseInstanceCreate />,
    });
    privateRoutes.push({
      path: 'company-course-instance/:courseInstanceId',
      element: <CompanyCourseInstanceEdit />,
    });
  }

  if (
    company &&
    (company.role.role === ROLES.SUPER_ADMIN ||
      company.role.role === ROLES.RESELLER_ADMIN ||
      company.role.role === ROLES.COMPANY_ADMIN ||
      company.role.role === ROLES.COMPANY_EXECUTIVE ||
      (company.role.role === ROLES.COMPANY_MANAGER &&
        company.role.access.includes(ROLE_ACCESS.ADD_REMOVE_EMPLOYMENTS)))
  ) {
    privateRoutes.push({
      path: 'company-add-employment',
      element: <CompanyEmploymentCreate />,
    });
  }

  if (
    company &&
    (managerCanSeeLibrary ||
      company.role.role === ROLES.SUPER_ADMIN ||
      company.role.role === ROLES.RESELLER_ADMIN ||
      company.role.role === ROLES.COMPANY_ADMIN)
  ) {
    privateRoutes.push({
      path: 'company-library/*',
      element: <CompanyLibrary />,
    });
    privateRoutes.push({
      path: 'create-company-competence',
      element: <CompanyCompetenceCreate />,
    });
    privateRoutes.push({
      path: 'create-company-competence/competence/:competenceId',
      element: <CompanyCompetenceCreate />,
    });
    privateRoutes.push({
      path: 'edit-company-competence/:companyCompetenceId',
      element: <CompanyCompetenceEdit />,
    });
    privateRoutes.push({
      path: 'company-files',
      element: <CompanyFiles />,
    });
  }

  if (
    company &&
    (managerCanSeeOrganisations ||
      company.role.role === ROLES.SUPER_ADMIN ||
      company.role.role === ROLES.RESELLER_ADMIN ||
      company.role.role === ROLES.COMPANY_ADMIN)
  ) {
    privateRoutes.push({
      path: 'company-organisation/*',
      element: <CompanyOrganisation />,
    });
  }

  if (
    company &&
    (company.role.role === ROLES.SUPER_ADMIN ||
      company.role.role === ROLES.RESELLER_ADMIN ||
      company.role.role === ROLES.COMPANY_ADMIN)
  ) {
    privateRoutes.push({
      path: 'company-tags',
      element: <CompanyTags />,
    });
    privateRoutes.push({
      path: 'company-tag-create',
      element: <CompanyTagCreate />,
    });
    privateRoutes.push({
      path: 'company-tag-update/:tagId',
      element: <CompanyTagUpdate />,
    });
    privateRoutes.push({
      path: 'company-authorizations',
      element: <CompanyManagers />,
    });
    privateRoutes.push({
      path: 'company-authorization-create',
      element: <CompanyManagerCreate />,
    });
    privateRoutes.push({
      path: 'company-authorization-update/:managerId',
      element: <CompanyManagerUpdate />,
    });
    privateRoutes.push({
      path: 'company-executives',
      element: <CompanyExecutives />,
    });
    privateRoutes.push({
      path: 'company-executive-create',
      element: <CompanyExecutiveCreate />,
    });
    privateRoutes.push({
      path: 'company-executive-update/:executiveId',
      element: <CompanyExecutiveUpdate />,
    });
    privateRoutes.push({
      path: 'company-settings',
      element: <CompanySettings />,
    });
    privateRoutes.push({
      path: 'company-settings/api-connections',
      element: <CompanyApiConnections />,
    });
    privateRoutes.push({
      path: 'company-settings/reminders',
      element: <CompanyReminders />,
    });
    privateRoutes.push({
      path: 'company-settings/expiring-warning',
      element: <CompanyExpiringWarning />,
    });
  }

  const routes: RouteObject[] = [
    {
      path: 'docs',
      element: <DocsLayout />,
      children: [
        {
          path: '',
          element: <Navigate to="/docs/overview/welcome" replace />,
        },
        {
          path: '*',
          element: <Docs />,
        },
      ],
    },
    {
      path: 'authentication',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    {
      path: 'create-user/:url',
      element: (
        <GuestGuard>
          <CreateUser />
        </GuestGuard>
      ),
    },
    {
      path: 'create-user-employee/:url',
      element: (
        <GuestGuard>
          <CreateEmploymentUser />
        </GuestGuard>
      ),
    },
    {
      path: 'create-password/:url',
      element: (
        <GuestGuard>
          <CreatePassword />
        </GuestGuard>
      ),
    },
    {
      path: 'connect-email/:url',
      element: <ConnectEmail />,
    },
    {
      path: 'connect-mobile/:url',
      element: <ConnectMobile />,
    },
    {
      path: 'fortnox-landing',
      element: <FortnoxLanding />,
    },
    {
      path: 'fortnox-create/:url',
      element: <FortnoxCreate />,
    },
    {
      path: 'course-instance/:token',
      element: <EducationCourseInstance />,
    },
    {
      path: 'sso-login',
      element: (
        <SsoLogin />
      ),
    },
    {
      path: 'sso-logout',
      element: (
        <SsoLogout />
      ),
    },
    {
      path: 'sso-create-user',
      element: (
        <GuestGuard>
          <SsoCreateUser />
        </GuestGuard>
      ),
    },
    {
      path: 'sso-connect-user',
      element: (
        <GuestGuard>
          <SsoConnectUser />
        </GuestGuard>
      ),
    },
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: privateRoutes,
    },
  ];

  return routes;
};
