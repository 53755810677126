const translation = {
  Settings: 'Settings',
  'Dark mode': 'Dark mode',
  Language: 'Language',
  Notifications: 'Notifications',
  Dashboard: 'Dashboard',
  Competences: 'Competences',
  Competence: 'Competence',
  History: 'History',
  Licenses: 'Licenses',
  License: 'License',
  Account: 'Account',
  Logout: 'Logout',
  'Add verification': 'Add verification',
  Name: 'Name',
  Phone: 'Phone',
  Description: 'Description',
  Cancel: 'Cancel',
  'Create a new competence': 'Create a new competence',
  'Your account': 'Your account',
  General: 'General',
  'Email addresses': 'Email addresses',
  'More options': 'More options',
  'Amount of requirements you fulfill from':
    'Amount of requirements you fulfill from',
  'Not valid requirements': 'Not valid requirements',
  'You have requirements that are not valid but you have handled them. Go to employments to see which requirements.':
    'You have requirements that are not valid but you have handled them. Go to employments to see which requirements.',
  Documentation: 'Documentation',
  'Need help': 'Need help',
  'Check our documentation': 'Check our documentation',
  'Add task': 'Add task',
  'Have you done the task?': 'Have you done the task?',
  'Expiring or expired competences': 'Expiring or expired competences',
  'If you choose to delete your account all the data that the system has on your account will be deleted permanently.':
    'If you choose to delete your account all the data that the system has on your account will be deleted permanently.',
  'Phone numbers': 'Phone numbers',
  'Add competences': 'Add competences',
  'Choose as requirements': 'Choose as requirements',
  'Choose requirements': 'Choose requirements',
  'Add company competences as requirements':
    'Add company competences as requirements',
  'Nice to know': 'Nice to know',
  'Save changes': 'Save changes',
  Certificates: 'Certificates',
  Library: 'Library',
  Authorizations: 'Authorizations',
  'Assign authorization': 'Assign authorization',
  'Update authorization': 'Update authorization',
  'Create license': 'Create license',
  'Revoke license': 'Revoke license',
  'Choose employees': 'Choose employees',
  'Send license': 'Send license',
  'Edit profiles': 'Edit profiles',
  'Add competences and certificates': 'Add competences and certificates',
  'Handle requirements': 'Handle requirements',
  'Connect employees': 'Connect employees',
  'Upload files': 'Upload files',
  Type: 'Type',
  Machine: 'Machine',
  Locations: 'Locations',
  Addresses: 'Addresses',
  Goods: 'Goods',
  'User has accepted the connection to your company.':
    'User has accepted the connection to your company.',
  'User has not yet accepted the connection to your company.':
    'User has not yet accepted the connection to your company.',
  'User cannot login to the system.': 'User cannot login to the system.',
  'Kompetensvy tags': 'Kompetensvy tags',
  'Internal tags': 'Internal tags',
  'Verify employee competences': 'Verify employee competences',
  Validated: 'Validated',
  'Needs validation' : 'Needs validation',
  'Missing verification' : 'Missing verification',
  'Verification needs renewal every %x% months': 'Verification needs renewal every %x% months',
  'Picture of both sides of the certificate is needed': 'Picture of both sides of the certificate is needed',
  'Picture of the certificate is needed': 'Picture of the certificate is needed',
  'Remove validate': 'Remove validate',
  'User has denied the connection to your company.':
    'User has denied the connection to your company.',
  'Conditions of the truck': 'Conditions of the truck',
  'Machine name': 'Machine name',
  Gear: 'Gear',
  Trucks: 'Trucks',
  'Machine driven lifting devices': 'Machine driven lifting devices',
  Lifts: 'Lifts',
  Cranes: 'Cranes',
  Inside: 'Inside',
  Outside: 'Outside',
  'Public road': 'Public road',
  'A1 - low lift order picker': 'A1 - low lift order picker',
  'A2 - Joint stacker': 'A2 - Joint stacker',
  'A3 - Low lift pickup truck': 'A3 - Low lift pickup truck',
  'A4 - Low lifter': 'A4 - Low lifter',
  'B1 - Counterbalance truck': 'B1 - Counterbalance truck',
  'B2 - High-lift stacker': 'B2 - High-lift stacker',
  'B3 - Reach truck': 'B3 - Reach truck',
  'B4 - High-lift pick-up truck': 'B4 - High-lift pick-up truck',
  'B5 - Narrow aisle truck': 'B5 - Narrow aisle truck',
  'B6 - Four-way truck': 'B6 - Four-way truck',
  'C2 - Wheel loader': 'C2 - Wheel loader',
  'C3a - Container truck': 'C3a - Container truck',
  'C3b - Timber truck': 'C3b - Timber truck',
  'C4 - Sideloader': 'C4 - Sideloader',
  'C5 - Border truck': 'C5 - Border truck',
  'C7 - Telescopic loaders': 'C7 - Telescopic loaders',
  'D1 - Drag/flatbed truck': 'D1 - Drag/flatbed truck',
  'D2 - Terminal tractor': 'D2 - Terminal tractor',
  'A1 - Traverse radio operated': 'A1 - Traverse radio operated',
  'A2 - Traverse hanging maneuvered': 'A2 - Traverse hanging maneuvered',
  'A3 - Traverse cab maneuvered': 'A3 - Traverse cab maneuvered',
  'B1 - Portal radio operated': 'B1 - Portal radio operated',
  'B2 - Portal hanging maneuvered': 'B2 - Portal hanging maneuvered',
  'B3 - Portal cab maneuvered': 'B3 - Portal cab maneuvered',
  'C1 - Portal radio operated': 'C1 - Portal radio operated',
  'C2 - Portal hanging maneuvered': 'C2 - Portal hanging maneuvered',
  'C3 - Portal cab maneuvered': 'C3 - Portal cab maneuvered',
  'D1 - Portal radio operated': 'D1 - Portal radio operated',
  'D2 - Portal hanging maneuvered': 'D2 - Portal hanging maneuvered',
  'D3 - Portal cab maneuvered': 'D3 - Portal cab maneuvered',
  'E1 - Portal radio operated': 'E1 - Portal radio operated',
  'E2 - Portal hanging maneuvered': 'E2 - Portal hanging maneuvered',
  'E3 - Portal cab maneuvered': 'E3 - Portal cab maneuvered',
  'F1 - Portal radio operated': 'F1 - Portal radio operated',
  'F2 - Portal hanging maneuvered': 'F2 - Portal hanging maneuvered',
  'F3 - Portal cab maneuvered': 'F3 - Portal cab maneuvered',
  'A1 - Scissors / column lift with support legs':
    'A1 - Scissors / column lift with support legs',
  'A2 - Track-bound scissor lift': 'A2 - Track-bound scissor lift',
  'A3 - Self-propelled scissors / column lift':
    'A3 - Self-propelled scissors / column lift',
  'B1 - Car / Trailer-mounted boom lift with support legs, caterpillar / crawler lift':
    'B1 - Car / Trailer-mounted boom lift with support legs, caterpillar / crawler lift',
  'B2 - Track-bound boom lift / under-bridge lift':
    'B2 - Track-bound boom lift / under-bridge lift',
  'B3 - Self-propelled boom lift / Folding arm lift':
    'B3 - Self-propelled boom lift / Folding arm lift',
  'Machine name (optional)': 'Machine name (optional)',
  'Workplace (optional)': 'Workplace (optional)',
  'Tasks (optional)': 'Tasks (optional)',
  'Goods/material (optional)': 'Goods/material (optional)',
  'Conditions of the truck (optional)': 'Conditions of the truck (optional)',
  'Gear that will be used (optional)': 'Gear that will be used (optional)',
  'Risk assessment comment (optional)': 'Risk assessment comment (optional)',
  'What machine will be used with this license.':
    'What machine will be used with this license.',
  'Where this license will be used.': 'Where this license will be used.',
  'What tasks/work will this license allow for.':
    'What tasks/work will this license allow for.',
  'What type of goods/material will be handled.':
    'What type of goods/material will be handled.',
  'Read the truck manual, we recommend you upload manual on the trucks that will be used.':
    'Read the truck manual, we recommend you upload manual on the trucks that will be used.',
  'Read the gear manual, we recommend you upload manual on the gear that will be used.':
    'Read the gear manual, we recommend you upload manual on the gear that will be used.',
  'We recommend that you upload complete documentation on the risk assessments based on the work that will be done regarding this license.':
    'We recommend that you upload complete documentation on the risk assessments based on the work that will be done regarding this license.',
  'Choose locations for where the license will be used.':
    'Choose locations for where the license will be used.',
  'At what addresses will the license be used.':
    'At what addresses will the license be used.',
  'Important information. If the license requires driving on a public road please make sure you follow legal compliances that apply to public roads.':
    'Important information. If the license requires driving on a public road please make sure you follow legal compliances that apply to public roads.',
  'Choose files that users can download when reading about this license.':
    'Choose files that users can download when reading about this license.',
  'We recommend that you fill out all the fields to ensure legal compliance. We also recommend seperate licenses for different types.':
    'We recommend that you fill out all the fields to ensure legal compliance. We also recommend seperate licenses for different types.',
  'Assign new managers': 'Assign new managers',
  'Manager information': 'Manager information',
  'Reminders are used to keep competences up to date. If a required competence is missing, expiring or expired reminders will be sent to selected users.':
    'Reminders are used to keep competences up to date. If a required competence is missing, expiring or expired reminders will be sent to selected users.',
  Report: 'Report',
  'Connected employees': 'Connected employees',
  'Create groups of managers to easier find them in the list.':
    'Create groups of managers to easier find them in the list.',
  'Choose which employees will get the role of being a manager, this will give them the option to administrate people under the chosen organisation tags. The users must have an account and be connected to your company.':
    'Choose which employees will get the role of being a manager, this will give them the option to administrate people under the chosen organisation tags. The users must have an account and be connected to your company.',
  Resellers: 'Resellers',
  Reseller: 'Reseller',
  'Add reseller': 'Add reseller',
  Companies: 'Companies',
  Employees: 'Employees',
  'ID06 Competences': 'ID06 Competences',
  'ID06 companies': 'ID06 companies',
  "Select a company who's API the information will be used to fetch ID06 competences":
    "Select a company who's API the information will be used to fetch ID06 competences",
  'First name': 'First name',
  'Last name': 'Last name',
  Address: 'Address',
  'Zip code': 'Zip code',
  City: 'City',
  Relative: 'Relative',
  'Expiring date for': 'Expiring date for',
  'Create certificate': 'Create',
  'You can choose multiple competences. If the competences you choose have a specific expiration time the system will automatically calculate how long the competences are valid.':
    'You can choose multiple competences. If the competences you choose have a specific expiration time the system will automatically calculate how long the competences are valid.',
  'Select competences': 'Select competences',
  'Select all': 'Select all',
  'Relatives phone number': 'Relatives phone number',
  'Contact person': 'Contact person',
  'Contact person phone': 'Contact person phone',
  'Your competences': 'Your competences',
  'Close menu': 'Close menu',
  'Open menu': 'Open menu',
  'Add license folder': 'Add license folder',
  Delete: 'Delete',
  'Rename license folder': 'Rename license folder',
  'Delete license folder': 'Delete license folder',
  'Delete selected license folder?': 'Delete selected license folder?',
  'Sort by': 'Sort by',
  'Name (asc)': 'Name (asc)',
  'Name (desc)': 'Name (desc)',
  'Description (asc)': 'Description (asc)',
  'Description (desc)': 'Description (desc)',
  'Date (desc)': 'Date (desc)',
  'Date (asc)': 'Date (asc)',
  'Type (asc)': 'Type (asc)',
  'Type (desc)': 'Type (desc)',
  'Rows per page': 'Rows per page',
  'Task description': 'Task description',
  'Answer type': 'Answer type',
  'Certificate is valid if task answer is valid':
    'Certificate is valid if task answer is valid',
  'Task expires': 'Task expires',
  'Task name': 'Task name',
  'Amount of months': 'Amount of months',
  Day: 'Day',
  Month: 'Month',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  'Months to spare': 'Months to spare',
  Example: 'Example',
  Print: 'Print',
  'Remove account': 'Remove account',
  'All requirements are handled': 'All requirements are handled',
  'Every year the 31st of May this competence will have to be validated again. We want 3 months to spare back in time. Peter got this competence 2020-04-01, because 1 of April is within 3 months of 31 of May Peters competence will be valid until 31 of May 2021. Instead of 31st of May 2020.':
    'Every year the 31st of May this competence will have to be validated again. We want 3 months to spare back in time. Peter got this competence 2020-04-01, because 1 of April is within 3 months of 31 of May Peters competence will be valid until 31 of May 2021. Instead of 31st of May 2020.',
  'Accepted if within number of months before':
    'Accepted if within number of months before',
  'Valid if other verification step is valid':
    'Valid if other verification step is valid',
  'Pick in the list which requirement you would like to add to to verify the competence.':
    'Pick in the list which requirement you would like to add to to verify the competence.',
  of: 'of',
  Search: 'Search',
  'Date of issue': 'Date of issue',
  'Search competences': 'Search competences',
  'Add certificate': 'Add certificate',
  'Watched competences': 'Watched competences',
  Valid: 'Valid',
  NotValid: 'Not valid',
  'Not valid': 'Not valid',
  Expiring: 'Expiring',
  'Expiring soon': 'Expiring soon',
  Expired: 'Expired',
  'Amount of requirements the employee fulfills':
    'Amount of requirements the employee fulfills',
  'Amount of requirements that needs to be handled':
    'Amount of requirements that needs to be handled',
  'Has no requirements': 'Has no requirements',
  Fulfills: 'Fulfills',
  'Invalid phone number': 'Invalid phone number',
  'Renew within': 'Renew within',
  month: 'month',
  months: 'months',
  'A task must be done': 'A task must be done',
  'Not fulfilled': 'Not fulfilled',
  'Company competences': 'Company competences',
  'Company competence': 'Company competence',
  'Create a new internal competence': 'Create a new internal competence',
  Requirements: 'Requirements',
  Handled: 'Handled',
  Handle: 'Handle',
  Expires: 'Expires',
  'Handled by': 'Handled by',
  'Turn off status for this competence for this person.':
    'Turn off status for this competence for this person.',
  'Not handled': 'Not handled',
  'All handled': 'All handled',
  'Not connected': 'Not connected',
  'The user has accepted the connection to your company.':
    'The user has accepted the connection to your company.',
  'Connect employee': 'Connect employee',
  Profile: 'Profile',
  'Remove employee': 'Remove employee',
  'Add employment': 'Add employment',
  'Employee information': 'Employee information',
  'Employment information': 'Employment information',
  'Social security number': 'Social security number',
  'Employment number': 'Employment number',
  'mobile number': 'mobile number',
  'Mobile number': 'Mobile number',
  'Import employees': 'Import employees',
  'Template file': 'Template file',
  'First name and last name is required. You also need to input atleast one email or phone number or social security number per person.':
    'First name and last name is required. You also need to input atleast one email or phone number or social security number per person.',
  email: 'email',
  Email: 'Email',
  Connected: 'Connected',
  'Remove certificate': 'Remove certificate',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the certificate.':
    'By pressing remove you acknowledge that this cannot be undone and you will remove the certificate.',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the account.':
    'By pressing remove you acknowledge that this cannot be undone and you will remove the account.',
  'Missing Handled': 'Missing Handled',
  'Missing certificate': 'Missing certificate',
  'Employee connected': 'Employee connected',
  'Create employment': 'Create employment',
  'Upload images': 'Upload images',
  'Choose type of verification': 'Choose type of verification',
  'First name is required': 'First name is required',
  'Create a new employee': 'Create a new employee',
  'Personal information': 'Personal information',
  'Add or remove organisations from employees':
    'Add or remove organisations from employees',
  'Action to take with selected organisations when saving':
    'Action to take with selected organisations when saving',
  'Add organisations to employees': 'Add organisations to employees',
  'Remove organisations from employees': 'Remove organisations from employees',
  Action: 'Action',
  'Edit organisations': 'Edit organisations',
  'No certificate or task added.': 'No certificate or task added.',
  'Last name is required': 'Last name is required',
  'Mobile BankID': 'Mobile BankID',
  'BankID on this unit': 'BankID on this unit',
  'Verify social security number': 'Verify social security number',
  Required: 'Required',
  'Connected email addresses': 'Connected email addresses',
  'Add email address': 'Add email address',
  'Email address': 'Email address',
  Add: 'Add',
  'To email': 'To email',
  'Waiting for answer': 'Waiting for answer',
  And: 'And',
  Sent: 'Sent',
  Date: 'Date',
  'Abort connecting employee': 'Cancel',
  'Phone number': 'Mobile number',
  'Add phone number': 'Add mobile number',
  'To number': 'To number',
  'Connected phone numbers': 'Connected mobile number',
  'Change company': 'Change company',
  Confirm: 'Confirm',
  Welcome: 'Welcome',
  Current: 'Current',
  'Old certificates': 'Old certificates',
  'Expiring date': 'Expiring date',
  'Create new': 'Create new',
  'Create new company competence': 'Create new company competence',
  'Kompetensvy competences': 'Kompetensvy competences',
  'Internal competences': 'Internal competences',
  'Create certificates': 'Create certificates',
  'Select file': 'Select file',
  'Select files': 'Select files',
  file: 'file',
  files: 'files',
  Drop: 'Drop',
  'here or click': 'here or click',
  browse: 'browse',
  Back: 'Back',
  'Add competence': 'Add competence',
  'Has expired': 'Has expired',
  'Create new account': 'Create new account',
  'Email or phone number': 'Email or phone number',
  'Email or phone': 'Email or phone',
  'Email or phone is required': 'Email or phone is required',
  'Forgot password?': 'Forgot password?',
  'Password is required': 'Password is required',
  Password: 'Password',
  'Password again': 'Password again',
  'Could not login': 'Could not login',
  'Sign in now': 'Sign in now',
  'Add employees': 'Add employees',
  'Missing employments': 'Missing employments',
  Edit: 'Edit',
  'Edit tags': 'Edit tags',
  'Competence tags': 'Competence tags',
  Missing: 'Missing',
  Notes: 'Notes',
  Certificate: 'Certificate',
  Copy: 'Copy',
  'Remove handled': 'Remove handled',
  'By pressing remove you acknowledge that this cannot be undone and you will remove handled.':
    'By pressing remove you acknowledge that this cannot be undone and you will remove handled.',
  'Copy permission': 'Copy permission',
  'Revoke permission': 'Revoke permission',
  'If you choose to revoke the permission, the permission is no longer valid.':
    'If you choose to revoke the permission, the permission is no longer valid.',
  'You can use the current permission to create a new one. If the new permission is ment to replace this permission make sure to revoke this permission.':
    'You can use the current permission to create a new one. If the new permission is ment to replace this permission make sure to revoke this permission.',
  Permission: 'Permission',
  'Custom permission': 'Custom permission',
  'Valid permission': 'Valid permission',
  'Missing permission': 'Missing permission',
  'Pending permission': 'Pending permission',
  'Assign permission': 'Assign permission',
  'Assign permission to the selected employees': 'Assign permission to the selected employees',
  'Assign work/driving permissions': 'Assign work/driving permission',
  'Assign work/driving permissions to the selected employees': 'Assign work/driving permissions to the selected employees',
  'Assign permission to employees': 'Assign permission to employees',
  'Selected employees': 'Selected employees',
  'Select permission': 'Select permission',
  'If the list is empty, there are no valid permissions for this competence.': 'If the list is empty, there are no valid permissions for this competence.',
  'Employees with competence': 'Employees with competence',
  Save: 'Save',
  Skip: 'Skip',
  'Your requirements': 'Your requirements',
  Preview: 'Preview',
  'Here you see your active employments. Click the boxes below to see what requirements you have.':
    'Here you see your active employments. Click the boxes below to see what requirements you have.',
  'This is a preview of your competence certificates, make sure everything is correct and press save competence.':
    'This is a preview of your competence certificates, make sure everything is correct and press save competence.',
  'Select employees you want to print or handle.':
    'Select employees you want to print or handle.',
  'Select employees you want to print.': 'Select employees you want to print.',
  Employments: 'Employments',
  Organisations: 'Organisations',
  'Missing organisations': 'Missing organisations',
  'Missing watched competences': 'Missing watched competences',
  'Required competences': 'Required competences',
  Reports: 'Reports',
  'Complete report': 'Complete report',
  Complete: 'Complete',
  'Required competences report': 'Required competences report',
  'Should be handled': 'Should be handled',
  ' I cant find my physical proof that i have this competence.':
    ' I cant find my physical proof that i have this competence.',
  'Calender year': 'Calender year',
  Homepage: 'Homepage',
  'Organisation requirements': 'Organisation requirements',
  'Shows statuses of requirements on employees':
    'Shows statuses of requirements on employees',
  'Individual required competence statuses':
    'Individual required competence statuses',
  'Message sent to employee': 'Message sent to employee',
  'Message could not be sent to mobile number':
    'Message could not be sent to mobile number',
  'Message sent to mobile number': 'Message sent to mobile number',
  'Message could not be sent to email': 'Message could not be sent to email',
  'Message sent to email': 'Message sent to email',
  'Connect users': 'Connect users',
  'Remove selected': 'Remove selected',
  'Remove selected company competences': 'Remove selected company competences',
  'Remove selected competences': 'Remove selected competences',
  selected: 'selected',
  'Clear selection': 'Clear selection',
  Employment: 'Employment',
  'Welcome to Kompetensvy': 'Welcome to Kompetensvy',
  'Go to login page.': 'Go to login page.',
  'User created succesfully': 'User created succesfully',
  'First name is required.': 'First name is required.',
  'Last name is required.': 'Last name is required.',
  'Password is required.': 'Password is required.',
  'This field must be checked': 'This field must be checked',
  'I have read the': 'I have read the',
  'Terms and Conditions': 'Terms and Conditions',
  'Fill in your information.': 'Fill in your information.',
  'Fill in your information. Your company will be created.':
    'Fill in your information. Your company will be created.',
  'Passwords must match': 'Passwords must match',
  Register: 'Register',
  Close: 'Close',
  Recall: 'Recall',
  'Risk assessment comment': 'Risk assessment comment',
  'Expiration date': 'Expiration date',
  'Issue date': 'Issue date',
  'Signed by': 'Signed by',
  'Signed/added by': 'Signed/added by',
  Workplace: 'Workplace',
  'Work tasks': 'Work tasks',
  'Fill in new password.': 'Fill in new password.',
  'Sign up': 'Sign up',
  'Competences marked with a star that you actively track in Kompetensvy.':
    'Competences marked with a star that you actively track in Kompetensvy.',
  'Search for tags to see what competences the employee fulfills against compared tag.':
    'Search for tags to see what competences the employee fulfills against compared tag.',
  'You have verified your social security number, you can now use your social security number to login.':
    'You have verified your social security number, you can now use your social security number to login.',
  'Your social security number': 'Your social security number',
  'Latest run': 'Latest run',
  'Monthly reminders': 'Monthly reminders',
  'Send reminders to admins about the state of the entire company':
    'Send reminders to admins about the state of the entire company',
  'Send reminders to executives about the state of managed organisations':
    'Send reminders to executives about the state of managed organisations',
  'Send reminders to manager about the state of managed organisations':
    'Send reminders to manager about the state of managed organisations',
  'Send reminders to employee about missing, expiring or expired competences':
    'Send reminders to employee about missing, expiring or expired competences',
  Update: 'Update',
  'Days between reminders': 'Days between reminders',
  'Send monthly reminders about expiring competences to admins, executives and managers':
    'Send monthly reminders about expiring competences to admins, executives and managers',
  'Could not register.': 'Could not register.',
  'Back to login page.': 'Back to login page.',
  'Register with email or phone number': 'Register with email or phone number',
  'Signing in...': 'Signing in...',
  'Signing out...': 'Signing out...',
  "Couldn't retrieve all required user data. Try again or contact support": "Couldn't retrieve all required user data. Try again or contact support",
  "Couldn't sign you in, try again. If the issue isn't resolved, log out of all your Microsoft accounts before retrying":
    "Couldn't sign you in, try again. If the issue isn't resolved, log out of all your Microsoft accounts before retrying",
  'Login failed, please try again or contact support': 'Login failed, please try again or contact support',
  "You need to allow access to the Kompetensvy application to be able to log in with Microsoft":
    "You need to allow access to the Kompetensvy application to be able to log in with Microsoft",
  "We couldn't find your user in our system. Fill in your information to create a user": "We couldn't find your user in our system. Fill in your information to create a user",
  'Connect user to company': 'Connect user to company',
  "We found your user in our system, but it wasn't connected to your company. To be able to login with Microsoft you need to connect your user to your company":
    "We found your user in our system, but it wasn't connected to your company. To be able to login with Microsoft you need to connect your user to your company",
  'Your user will be connected to "{{company}}". By accepting this you acknowledge that administrators of "{{company}}" can administer your competences, personal information etc.':
    'Your user will be connected to "{{company}}". By accepting this you acknowledge that administrators of "{{company}}" can administer your competences, personal information etc.',
  'You need to accept that your user will be connected to the company for Microsoft login to work':
    'You need to accept that your user will be connected to the company for Microsoft login to work',
  'Send a connection request to the employee.':
    'Send a connection request to the employee.',
  'Send connection request': 'Send connection request',
  'Open PDF viewer with selected employees':
    'Open PDF viewer with selected employees',
  'Delete selected employees': 'Delete selected employees',
  'Remove connection': 'Remove connection',
  'Reset password': 'Reset password',
  'Could not reset password.': 'Could not reset password.',
  'Your licenses': 'Your licenses',
  Accepted: 'Accepted',
  Starred: 'Starred',
  'Could not log in': 'Could not log in',
  'Employees competences': 'Employees competences',
  Accept: 'Accept',
  Denied: 'Denied',
  Deny: 'Deny',
  Remove: 'Remove',
  Previous: 'Previous',
  Next: 'Next',
  Verifications: 'Verifications',
  Tasks: 'Tasks',
  Task: 'Task',
  'Add new': 'Add new',
  'Add new answer': 'Add new answer',
  Answer: 'Answer',
  'Add tag': 'Add tag',
  Tags: 'Tags',
  'Choose tags': 'Choose tags',
  Files: 'Files',
  Employee: 'Employee',
  'To employees': 'To employees',
  Appendix: 'Appendix',
  'Date of birth': 'Date of birth',
  'Edit company competence': 'Edit company competence',
  'Missing permissions': 'Missing permissions',
  'No permissions added yet. Keep track of your permissions digitally in kompetensvy.':
    'No permissions added yet. Keep track of your permissions digitally in kompetensvy.',
  'Competences that the user currently has.':
    'Competences that the user currently has.',
  'Requirements based on the organisation. For example from a role.':
    'Requirements based on the organisation. For example from a role.',
  'Create internal competence based on global competence':
    'Create internal competence based on global competence',
  'Read more': 'Read more',
  'Edit internal competence': 'Edit internal competence',
  'Duplicate internal competence': 'Duplicate internal competence',
  'Company competence duplicated': 'Company competence duplicated',
  'View PDF': 'View PDF',
  'Excel alternatives': 'Excel alternatives',
  'Add Handle': 'Add Handle',
  'Download excel': 'Download excel',
  'Open in new tab': 'Open in new tab',
  'Go to certificate': 'Go to certificate',
  'Remove employees': 'Remove employees',
  'Selected competences will be shown in the report.':
    'Selected competences will be shown in the report.',
  Excel: 'Excel',
  'Show competence image': 'Show competence image',
  'All competences tracked by the company that the employee have.':
    'All competences tracked by the company that the employee have.',
  'Company competences that the employee have.':
    'Company competences that the employee have.',
  'Global competences tracked by the company that the employee have.':
    'Global competences tracked by the company that the employee have.',
  'Competences tracked by the company that the employee is missing.':
    'Competences tracked by the company that the employee is missing.',
  'Choose what extra information to show in the report.':
    'Choose what extra information to show in the report.',
  'Show employee social security number':
    'Show employee social security number',
  'Show employee social security number last digits':
    'Show employee social security number last digits',
  'Show employee email address': 'Show employee email address',
  'Show employee mobile number': 'Show employee mobile number',
  'Choose what competences from Kompetensvys library you like to keep track of.':
    'Choose what competences from Kompetensvys library you like to keep track of.',
  'From Kompetensvys library': 'From Kompetensvys library',
  'Add organisation part': 'Add organisation part',
  'Remove organisation': 'Remove organisation',
  'Rename organisation': 'Rename organisation',
  'Add requirements on chosen organisation.':
    'Add requirements on chosen organisation.',
  'Delete selected organisation and all children?':
    'Delete selected organisation and all children?',
  'Add new certificate': 'Add new certificate',
  'Modify global competence (optional)': 'Modify global competence (optional)',
  'If you have a new certificate that renews this competence, simply add it by pressing the add new certificate button and filling in the information.':
    'If you have a new certificate that renews this competence, simply add it by pressing the add new certificate button and filling in the information.',
  'Create competence': 'Create competence',
  'Employee requirements': 'Employee requirements',
  'Required competences missing': 'Required competences missing',
  'The employee has no required competences yet.':
    'The employee has no required competences yet.',
  'Social security number must be valid':
    'Social security number must be valid',
  'Mobile number must be valid': 'Mobile number must be valid',
  'Email must be valid': 'Email must be valid',
  'Select competence to handle': 'Select competence to handle',
  'Description is needed': 'Description is needed',
  'Connection request': 'Connection request',
  'has sent a license for': 'has sent a license for',
  'Remove all': 'Remove all',
  'date is a required field': 'date is a required field',
  Validate: 'Validate',
  'Not validated': 'Not validated',
  'Verified by': 'Verified by',
  'Remove competence': 'Remove competence',
  'Add company': 'Add company',
  Company: 'Company',
  Features: 'Features',
  'Add an email address or phone number. If the user already has an account the user will be added as admin. If the user does not have an account a email or sms will be sent with instruction for how to create an account. The created user will be an admin.':
    'Add an email address or phone number. If the user already has an account the user will be added as admin. If the user does not have an account a email or sms will be sent with instruction for how to create an account. The created user will be an admin.',
  'Create admin': 'Create admin',
  'Pending admin creations': 'Pending admin creations',
  Admins: 'Admins',
  Mutations: 'Mutations',
  'Company mutations': 'Company mutations',
  Users: 'Users',
  'User mutations': 'User mutations',
  'Select machine types': 'Select machine types',
  'Permission name': 'Permission name',
  'Select machine and type': 'Select machine and type',
  'Type is required': 'Type is required',
  'Yes/No': 'Yes/No',
  Text: 'Text',
  'Competence expires': 'Competence expires',
  'This competence expires and need to be certified again.':
    'This competence expires and need to be certified again.',
  'Reoccurs in (months)': 'Reoccurs in (months)',
  'Competence expires at this date every year':
    'Competence expires at this date every year',
  'Verification': 'Verification',
  'The users must upload atleast one picture to validate the competence.': 'The users must upload atleast one picture to validate the competence.',
  'The users must upload atleast two pictures to validate the competence.': 'The users must upload atleast two pictures to validate the competence.',
  'Machine permission is required': 'Machine permission is required',
  'Machine permission is required for:': 'Machine permission is required for:',
  'The competence needs a valid machine permission': 'The competence needs a valid machine permission',
  'Permission expires with the competence': 'Permission expires with the competence',
  'An individual date will be set for each employee that gets assigned the permission': 'An individual date will be set for each employee that gets assigned the permission',
  'Use': 'Use',
  'Allow': 'Allow',
  'Individual expiration date':
    'Individual expiration date',
  'Verification number': 'Verification number',
  'Add employee': 'Add employee',
  'Select employees': 'Select employees',
  'Send reminder': 'Send reminder',
  'Certificate added': 'Certificate added',
  'Task answer removed': 'Task answer removed',
  'Connection email sent': 'Connection email sent',
  'Connection removed': 'Connection removed',
  'Internal competence added': 'Internal competence added',
  'Internal competence updated': 'Internal competence updated',
  'Image updated': 'Image updated',
  'Verifications updated': 'Verifications updated',
  'Files updated': 'Files updated',
  'Tags updated': 'Tags updated',
  'Competence removed': 'Competence removed',
  'Employee disconnected': 'Employee disconnected',
  'Company added': 'Company added',
  'Company updated': 'Company updated',
  'Company profile updated': 'Company profile updated',
  'Company removed': 'Company removed',
  'Favorite toggled': 'Favorite toggled',
  'Competence added': 'Competence added',
  'Competence updated': 'Competence updated',
  'Certificate verified toggle': 'Certificate verified toggle',
  'Certificate removed': 'Certificate removed',
  'Employees connections sent': 'Employees connections sent',
  'Employees connections resent': 'Employees connections resent',
  'Connection sent': 'Connection sent',
  'Employment added': 'Employment added',
  'Employment organisations updated': 'Employment organisations updated',
  'Employments organisations updated': 'Employments organisations updated',
  'Employee updated': 'Employee updated',
  'Employee removed': 'Employee removed',
  'Employees removed': 'Employees removed',
  'Handled removed': 'Handled removed',
  'Saved connection': 'Saved connection',
  'ID06 certificates reloaded': 'ID06 certificates reloaded',
  'License folder added': 'License folder added',
  'License folder renamed': 'License folder renamed',
  'License folder removed': 'License folder removed',
  'License added': 'License added',
  'Employee added': 'Employee added',
  'License updated': 'License updated',
  'Manager added': 'Manager added',
  'Manager updated': 'Manager updated',
  'Managers removed': 'Managers removed',
  'Organisation added': 'Organisation added',
  'Organisation renamed': 'Organisation renamed',
  'Organisation removed': 'Organisation removed',
  'Competences added to organisation': 'Competences added to organisation',
  'Competences removed from organisation':
    'Competences removed from organisation',
  'Permission added': 'Permission added',
  'Permission updated': 'Permission updated',
  'Machine type updated': 'Machine type updated',
  'Permission revoked': 'Permission revoked',
  'User saved': 'User saved',
  'Admin removed': 'Admin removed',
  'Reseller added': 'Reseller added',
  'Reseller updated': 'Reseller updated',
  'Reseller removed': 'Reseller removed',
  'Tag added': 'Tag added',
  'Tag removed': 'Tag removed',
  'Email sent': 'Email sent',
  'Email removed': 'Email removed',
  'SMS sent': 'SMS sent',
  'Mobile removed': 'Mobile removed',
  'User notification removed': 'User notification removed',
  'Connection request sent to user': 'Connection request sent to user',
  'marked employees have email or mobile numbers and will get a message.':
    'marked employees have email or mobile numbers and will get a message.',
  'People in the list below are missing email or mobile number.':
    'People in the list below are missing email or mobile number.',
  'People in the list below already have a connection request sent to them.':
    'People in the list below already have a connection request sent to them.',
  'Employees that are already connected.':
    'Employees that are already connected.',
  'Only connected employees are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected.':
    'Only connected employees are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected.',
  'Only connected employees or employees that have denied the permission are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected. If the employee has denied the permission you can send it again.':
    'Only connected employees or employees that have denied the permission are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected. If the employee has denied the permission you can send it again.',
  'The competence will have to be re-validated every year on the specific month and day you choose.':
    'The competence will have to be re-validated every year on the specific month and day you choose.',
  'The competence will have to be re-validated after the date set on each individual competence.':
    'The competence will have to be re-validated after the date set on each individual competence.',
  'The competence will have to be re-validated after the amount of months you choose.':
    'The competence will have to be re-validated after the amount of months you choose.',
  'The user answers by moving a switch.':
    'The user answers by moving a switch.',
  'The user has to input a date.': 'The user has to input a date.',
  'The users answers by writing a text.':
    'The users answers by writing a text.',
  'The users most do the task to validate the competence.':
    'The users most do the task to validate the competence.',
  'Permission information': 'Permission information',
  'Build your permission, we recommend that you detail your permissions as much as possible for legal compliance.':
    'Build your permission, we recommend that you detail your permissions as much as possible for legal compliance.',
  'What functionality should exist on the employees.':
    'What functionality should exist on the employees.',
  'What functionality should exist on the company.':
    'What functionality should exist on the company.',
  'What functionality should the manager have access to. If you choose none, the manager will have read only access.':
    'What functionality should the manager have access to. If you choose none, the manager will have read only access.',
  'Add and remove employments under managed organisations':
    'Can add and remove employments under managed organisations',
  'Administrate employee information': 'Administrate employee information',
  'Attest and remove attests': 'Attest and remove attests',
  'Manage employees permissions': 'Manage employees permissions',
  Access: 'Access',
  'If you choose to remove this competence this is a permanent action, it cannot be undone.':
    'If you choose to remove this competence this is a permanent action, it cannot be undone.',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the competence.':
    'By pressing remove you acknowledge that this cannot be undone and you will remove the competence.',
  'Create a organisation structure and requirements against for example units, departments, roles, projects...':
    'Create a organisation structure and requirements against for example units, departments, roles, projects...',
  'Choose files that users can download when reading about this competence.':
    'Choose files that users can download when reading about this competence.',
  'Expected date to be resolved': 'Expected date to be resolved',
  'Add email address or phone number': 'Add email address or phone number',
  'Turn off the status for this competence on this user.':
    'Turn off the status for this competence on this user.',
  'Reset your password with email or phone number':
    'Reset your password with email or phone number',
  'If you choose a competence from the competences list it will fill out the form with information based on Kompetensvys library. You can change any information and when you save you will have created a internal competence that references the one from Kompetensvys library. This means that if a user fulfills the internal competence it also fulfills the one from Kompetensvys library.':
    'If you choose a competence from the competences list it will fill out the form with information based on Kompetensvys library. You can change any information and when you save you will have created a internal competence that references the one from Kompetensvys library. This means that if a user fulfills the internal competence it also fulfills the one from Kompetensvys library.',
  'Add an email address or phone number. If the user already has an account a connection request will be sent to the user. If the user does not have an account one will be created for them.':
    'Add an email address or phone number. If the user already has an account a connection request will be sent to the user. If the user does not have an account one will be created for them.',
  'A notification has been added to this account, the employee needs to log in and accept the request.':
    'A notification has been added to this account, the employee needs to log in and accept the request.',
  'When the employee has opened the email/text and created an account/accepted the connection this status will change.':
    'When the employee has opened the email/text and created an account/accepted the connection this status will change.',
  'By pressing yes you acknowledge that this cannot be undone and you will remove the employee.':
    'By pressing yes you acknowledge that this cannot be undone and you will remove the employee.',
  'By continuing you acknowledge that this cannot be undone and that you will remove the following employees from the company:':
    'By continuing you acknowledge that this cannot be undone and that you will remove the following employees from the company:',
  'Deselect employees by clicking on their x icon. Deselected employees will not be removed.':
    'Deselect employees by clicking on their x icon. Deselected employees will not be removed.',
  'If you choose to remove this employee this is a permanent action, it cannot be undone. If this employee has connected an account the user will lose the employment but the account remains.':
    'If you choose to remove this employee this is a permanent action, it cannot be undone. If this employee has connected an account the user will lose the employment but the account remains.',
  'would like to merge their account with this one. If it is not you who sent this request you should deny this. If you accept you will be logged out and the accounts will merge, afterwards you will be able to login from the other account. Competences and employments will follow to the other account.':
    'would like to merge their account with this one. If it is not you who sent this request you should deny this. If you accept you will be logged out and the accounts will merge, afterwards you will be able to login from the other account. Competences and employments will follow to the other account.',
  'has sent a connection request to you. This means they will be able to administrate your competences, licenses, profile and more.':
    'has sent a connection request to you. This means they will be able to administrate your competences, licenses, profile and more.',
  'A reset token has been sent to the email adress or phone number.':
    'A reset token has been sent to the email adress or phone number.',
  'A message has been sent to the email adress or phone number.':
    'A message has been sent to the email adress or phone number.',
  'Your employer has asked you to create an account. Fill in your information.':
    'Your employer has asked you to create an account. Fill in your information.',
  'No watched competences yet. A great starting point is to first decide what competences you want to track in Kompetensvy. By choosing what competences you want to keep track of its easier and less messy to see every competence that is in the system. If you cant find the ones you want to keep track of, simply add it as your own internal competence and set your own demands on how it needs to be validated.':
    'No watched competences yet. A great starting point is to first decide what competences you want to track in Kompetensvy. By choosing what competences you want to keep track of its easier and less messy to see every competence that is in the system. If you cant find the ones you want to keep track of, simply add it as your own internal competence and set your own demands on how it needs to be validated.',
  'No organisation tree added yet. To easier keep track of your employees we recommend that you build a organisation tree and place competences that is required to fulfill for a employee. When you have made your first organisation you can go into your employees and add that organisation to the user so the system shows flags if the competences in the organsation branch is valid, expiring or has expired.':
    'No organisation tree added yet. To easier keep track of your employees we recommend that you build a organisation tree and place competences that is required to fulfill for a employee. When you have made your first organisation you can go into your employees and add that organisation to the user so the system shows flags if the competences in the organsation branch is valid, expiring or has expired.',
  'Your company is missing employees. To start adding your employees click the button down below. After you have created your list of employees you can start adding their competences, building demands and much more.':
    'Your company is missing employees. To start adding your employees click the button down below. After you have created your list of employees you can start adding their competences, building demands and much more.',
  'We suggest you upload images of your physical certificate, front and backside. Or anywhere that shows relevant information for someone who might control your certificate.':
    'We suggest you upload images of your physical certificate, front and backside. Or anywhere that shows relevant information for someone who might control your certificate.',
  'A text has been sent to this number, open the text and follow the instructions.':
    'A text has been sent to this number, open the text and follow the instructions.',
  'A user with this number already exists. A notification has been added to this account, log in and accept the request.':
    'A user with this number already exists. A notification has been added to this account, log in and accept the request.',
  'These are the phone numbers you have connected to your account.':
    'These are the phone numbers you have connected to your account.',
  'Add your phone number as a way to login. You will get a text with instructions. You can use multiple phone numbers.':
    'Add your phone number as a way to login. You will get a text with instructions. You can use multiple phone numbers.',
  'A email has been sent to this address, open the email and follow the instructions.':
    'A email has been sent to this address, open the email and follow the instructions.',
  'A account with this email aldready exists. A notification has been added to this account, log in and accept the request.':
    'A account with this email aldready exists. A notification has been added to this account, log in and accept the request.',
  'Add your email address as a way to login. You will get a email with instructions. You can use multiple emails.':
    'Add your email address as a way to login. You will get a email with instructions. You can use multiple emails.',
  'These are the email adresses you have connected to your account.':
    'These are the email adresses you have connected to your account.',
  'Verify your social security number. This requires you to accept with BankID. When you have accepted you can use BankID to login instead of a password.':
    'Verify your social security number. This requires you to accept with BankID. When you have accepted you can use BankID to login instead of a password.',
  'Welcome. You have not added any competences yet. To be able to keep track of your competences press the button below to start adding them.':
    'Welcome. You have not added any competences yet. To be able to keep track of your competences press the button below to start adding them.',
  'By adding verifications you enable different ways to make sure a user has this competence. If you choose not to add any verification the competence will  only require a date to be valid and it will never expire.':
    'By adding verifications you enable different ways to make sure a user has this competence. If you choose not to add any verification the competence will  only require a date to be valid and it will never expire.',
  Permissions: 'Permissions',
  'Work/driving permission': 'Work/driving permission',
  'Work/driving permissions': 'Work/driving permissions',
  'Templates work/driving permissions': 'Templates work/driving permissions',
  'Missing work/driving permissions': 'Missing work/driving permissions',
  'Read more & answer': 'Read more & answer',
  'Accept or deny permission': 'Accept or deny permission',
  'Certified by global competence ': 'Certified by global competence - ',
  'Organisation have requirements': 'Organisation have requirements',
  'Status for company permissions': 'Status for company permissions',
  Administration: 'Administration',
  'Machine types': 'Machine types',
  'Missing competences': 'Missing competences',
  'Show missing competences in report': 'Show missing competences in report',
  Organisation: 'Organisation',
  Favorites: 'Favorites',
  name: 'name',
  description: 'description',
  All: 'All',
  'Remember that Kompetensvy is the sender, please write who sent the message.':
    'Remember that Kompetensvy is the sender, please write who sent the message.',
  Internal: 'Internal',
  Global: 'Global',
  User: 'User',
  BankID: 'BankID',
  'Your employments': 'Your employments',
  'Select photo': 'Select photo',
  cancel: 'cancel',
  done: 'done',
  Active: 'Active',
  Revoked: 'Revoked',
  'Remove managers': 'Remove managers',
  'We recommend that you write who you are in the message.':
    'We recommend that you write who you are in the message.',
  'Name is required': 'Name is required',
  Revoke: 'Revoke',
  'Revoke selected': 'Revoke selected',
  'Revoke permissions': 'Revoke permissions',
  'By pressing revoke you acknowledge that this cannot be undone.':
    'By pressing revoke you acknowledge that this cannot be undone.',
  'Your permissions': 'Your permissions',
  'Not answerd': 'Not answerd',
  Connect: 'Connect',
  Declined: 'Declined',
  'Download excel file of user competences':
    'Download excel file of user competences',
  Status: 'Status',
  'All certificates are validated': 'All certificates are validated',
  'Amount of certificates that needs to be validated':
    'Amount of certificates that needs to be validated',
  'Remove employee connection': 'Remove employee connection',
  'By pressing yes you acknowledge that you will remove the connection to the employee.':
    'By pressing yes you acknowledge that you will remove the connection to the employee.',
  'Company competences or changed competences.':
    'Company competences or changed competences.',
  'Valid competences': 'Valid competences',
  Handeld: 'Handeld',
  'Not handeld': 'Not handeld',
  'Machine type': 'Machine type',
  'Company is required': 'Company is required',
  'File is required': 'File is required',
  'Include': 'Include',
  'Global competences': 'Global competences',
  'Accountable persons': 'Accountable persons',
  'has sent a permission for': 'has sent a permission for',
  Overview: 'Overview',
  Listview: 'Listview',
  'Hide missing': 'Hide missing',
  'Hide global': 'Hide global',
  'Hide internal': 'Hide internal',
  'All competences in company library.': 'All competences in company library.',
  Others: 'Others',
  'Global competences that the employee has thats not in the company library.':
    'Global competences that the employee has thats not in the company library.',
  Create: 'Create',
  'This person is accountable that the work is done correctly and by competent personal.':
    'This person is accountable that the work is done correctly and by competent personal.',
  'Only connected emplyees are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected.':
    'Only connected emplyees are shown. Permissions needs to be accepted by the employee to be valid therfor only connected employees can be selected.',
  Issued: 'Issued',
  'Only employees with a social security number will be fetched.':
    'Only employees with a social security number will be fetched.',
  'Fetch ID06 certificates': 'Fetch ID06 certificates',
  Fetch: 'Fetch',
  'Social security number fetched from employees user and is therefore locked.':
    'Social security number fetched from employees user and is therefore locked.',
  'Choose which employees will get the role of being a executive, this will give them the option to administrate people under the whole organisation. The users must have an account and be connected to your company.':
    'Choose which employees will get the role of being a executive, this will give them the option to administrate people under the whole organisation. The users must have an account and be connected to your company.',
  Executives: 'Executives',
  'No explanation': 'No explanation',
  Explanation: 'Explanation',
  'This competence is required. If you cant add all required information to make the competence valid explain why.':
    'This competence is required. If you cant add all required information to make the competence valid explain why.',
  Explain: 'Explain',
  Invoices: 'Invoices',
  Reminders: 'Reminders',
  'Send sms or email to employees.': 'Send sms or email to employees.',
  'Send email': 'Send email',
  Found: 'Found',
  Tree: 'Tree',
  List: 'List',
  'Send sms': 'Send sms',
  'Send both sms and email': 'Send both sms and email',
  'Message to employee': 'Message to employee',
  'Employees with no email and mobile number will not get a message.':
    'Employees with no email and mobile number will not get a message.',
  'Competence status': 'Competence status',
  'Shows the status of handled requirements.':
    'Shows the status of handled requirements.',
  'Handled status': 'Handled status',
  'Certificate front file is required': 'Certificate front file is required',
  'Certificate front and back files are required':
    'Certificate front and back files are required',
  'By pressing yes you acknowledge that this cannot be undone and you will remove the managers.':
    'By pressing yes you acknowledge that this cannot be undone and you will remove the managers.',
  'Assign executives': 'Assign executives',
  'Remove executives': 'Remove executives',
  'By pressing yes you acknowledge that this cannot be undone and you will remove the executives.':
    'By pressing yes you acknowledge that this cannot be undone and you will remove the executives.',
  'Show employee image': 'Show employee image',
  LatestActivity: 'Active',
  'Must be a valid email': 'Must be a valid email',
  'Email is required': 'Email is required',
  'A message has been sent to the email address.':
    'A message has been sent to the email address.',
  'Reset your password with email': 'Reset your password with email',
  'A reset token has been sent to the email adress':
    'A reset token has been sent to the email adress',
  'is required': 'is required',
  'Change reseller': 'Change reseller',
  'Invalid date': 'Invalid date',
  'The number of employees': 'The number of employees',
  'Employees that have an account in Kompetensvy':
    'Employees that have an account in Kompetensvy',
  'Amount of certificates that has been added':
    'Amount of certificates that has been added',
  'Discover the latest news from Kompetensvy.':
    'Discover the latest news from Kompetensvy.',
  Newer: 'Newer',
  'Older news': 'Older news',
  By: 'By',
  'You have': 'You have',
  'See all': 'See all',
  'Not valid competences': 'Not valid competences',
  'not valid competences to handle': 'not valid competences to handle',
  'Expiring competences': 'Expiring competences',
  'expiring competences to handle': 'expiring competences to handle',
  'Expired competences': 'Expired competences',
  'expired competences to handle': 'expired competences to handle',
  'user connect request to handle': 'user connect request to handle',
  'employment request to handle': 'employment request to handle',
  'permission to handle': 'permission to handle',
  'There are no notifications': 'There are no notifications',
  'Must have at least': 'Must have at least',
  'Cant reset password?': 'Cant reset password?',
  'Common issues': 'Common issues',
  'If you cannot reset your password you do not have an account registered to that email address.':
    'If you cannot reset your password you do not have an account registered to that email address.',
  'Check your email inbox for a mail from Info@kompetensvy.se, you might have to search in the junk mail.':
    'Check your email inbox for a mail from Info@kompetensvy.se, you might have to search in the junk mail.',
  'If this does not solve your problem, please contact your administrator or us at info@kompetensvy.se and we will look into it.':
    'If this does not solve your problem, please contact your administrator or us at info@kompetensvy.se and we will look into it.',
  'Add certificates to selected employees':
    'Add certificates to selected employees',
  'Export selected employees to PDF or Excel':
    'Export selected employees to PDF or Excel',
  'Due date': 'Due date',
  Run: 'Run',
  Swedish: 'Swedish',
  English: 'English',
  'Can administrate organisations': 'Can administrate organisations',
  'Can administrate library': 'Can administrate library',
  'Allows the person to favourite competences and add internal competences':
    'Allows the person to favourite competences and add internal competences',
  'Allows the person to make changes to the organisation and requirements.':
    'Allows the person to make changes to the organisation and requirements.',
  'Calender year shows how many will expire within the next year':
    'Calender year shows how many will expire within the next year',
  'Amount of months before the due date status begins.':
    'Amount of months before the due date status begins.',
  'A employee has a competence that expires 2022-01-01, the system will show a yellow status from 2021-06-01 as standard.':
    'A employee has a competence that expires 2022-01-01, the system will show a yellow status from 2021-06-01 as standard.',
  'Choose how many months in advance the system shows yellow status. This does not change when a competence expires, it only decides how early Kompetensvy should show yellow status. This allows control on how far ahead you can handle a competence and how early to get mail for expiring competences.':
    'Choose how many months in advance the system shows yellow status. This does not change when a competence expires, it only decides how early Kompetensvy should show yellow status. This allows control on how far ahead you can handle a competence and how early to get mail for expiring competences.',
  'Employees with organisations': 'Employees with organisations',
  'Requirements on organisations': 'Requirements on organisations',
  Yes: 'Yes',
  'All validated competences': 'All validated competences',
  'Not validated competences': 'Not validated competences',
  Change: 'Change',
  Theme: 'Theme',
  'Expiring requirements': 'Expiring requirements',
  'Here you can see things you need to handle':
    'Here you can see things you need to handle',
  'You have requirements that are expiring but you have handled them. Go to employments to see which requirements.':
    'You have requirements that are expiring but you have handled them. Go to employments to see which requirements.',
  'New employees imported': 'New employees imported',
  'New competence added': 'New competence added',
  'Updated company competence name': 'Updated company competence name',
  'Update company competence verifications':
    'Update company competence verifications',
  'Update company competence files': 'Update company competence files',
  'Removed company competence': 'Removed company competence',
  'Updated company competence image': 'Updated company competence image',
  'Favorite toggle': 'Favorite toggle',
  'Employee connect email': 'Employee connect email',
  'Delete employee connect email': 'Delete employee connect email',
  'Accept employee connect': 'Accept employee connect',
  'Update employment organisations': 'Update employment organisations',
  'Employee profile updated': 'Employee profile updated',
  'Update employment image': 'Update employment image',
  'Remove employment': 'Remove employment',
  'Add handled': 'Add handled',
  'Add manager': 'Add manager',
  'Update manager': 'Update manager',
  'Add organisation': 'Add organisation',
  'Add competences to organisation': 'Add competences to organisation',
  'Remove competences from organisation':
    'Remove competences from organisation',
  'Remove company competences from organisation':
    'Remove company competences from organisation',
  'Update employments organisations': 'Update employments organisations',
  'Add permission': 'Add permission',
  'Employees resend connect': 'Employees resend connect',
  'Update company reminders': 'Update company reminders',
  'Add handled by employee': 'Add handled by employee',
  'Remove handled by employee': 'Remove handled by employee',
  'Add organisation tree information': 'Add organisation tree information',
  'Send message': 'Send message',
  ImportEmployments: 'Import employments',
  RemoveCertificate: 'Remove certificate',
  AddCertificates: 'Add certificates',
  AddCompanyCompetence: 'Add company competence',
  UpdateCompanyCompetence: 'Update company competence',
  UpdateCompanyCompetenceVerifications:
    'Update company competence verifications',
  UpdateCompanyCompetenceFiles: 'Update company competence files',
  RemoveCompanyCompetence: 'Remove company competence',
  UpdateCompanyCompetenceImage: 'Update company competence image',
  ToggleFavorite: 'Toggle favorite',
  EmployeeConnectEmail: 'Employee connect email',
  DeleteEmployeeConnectEmail: 'Delete employee connect email',
  AcceptEmployeeConnect: 'Accept employee connect',
  AddEmployment: 'Add employment',
  UpdateEmploymentOrganisations: 'Update employment organisations',
  UpdateEmploymentProfile: 'Update employment profile',
  UpdateEmploymentImage: 'Update employment image',
  RemoveEmployment: 'Remove employment',
  AddHandled: 'Add handled',
  RemoveHandled: 'Remove handled',
  AddManager: 'Add manager',
  UpdateManager: 'Update manager',
  AddOrganisation: 'Add organisation',
  RenameOrganisation: 'Rename organisation',
  RemoveOrganisation: 'Remove organisation',
  AddCompetencesToOrganisation: 'Add competences to organisation',
  RemoveCompetencesFromOrganisation: 'Remove competences from organisation',
  AddCompanyCompetencesToOrganisation:
    'Add company competences to organisation',
  RemoveCompanyCompetencesFromOrganisation:
    'Remove company competences from organisation',
  RemoveManagers: 'Remove managers',
  UpdateEmploymentsOrganisations: 'Update employments organisations',
  AddPermission: 'Add permission',
  EmployeesResendConnect: 'Employees resend connect',
  UpdateCompanyReminders: 'Update company reminders',
  AddHandledByEmployee: 'Add handled by employee',
  RemoveHandledByEmployee: 'Remove handled by employee',
  AddOrganisationTreeInformation: 'Add organisation tree information',
  SendMessage: 'Send message',
  'Search by name, social security number or employment number':
    'Search by name, social security number or employment number',
  Filters: 'Filters',
  'SSG card number': 'SSG card number',
  'ID06 card number': 'ID06 card number',
  'Filter organisations': 'Filter organisations',
  'Filter statuses': 'Filter statuses',
  'You need to specify employment organisation.':
    'You need to specify employment organisation.',
  'Add authorizations': 'Add authorizations',
  'Send reminders to authorized people about the state of their organisations':
    'Send reminders to authorized people about the state of their organisations',
  'Company files': 'Company files',
  'Files added': 'Files added',
  Download: 'Download',
  'Files deleted': 'Files deleted',
  'Delete files': 'Delete files',
  'By pressing remove you acknowledge that this cannot be undone.':
    'By pressing remove you acknowledge that this cannot be undone.',
  'You have selected a competence from the global library and that competence is marked as favorite in your company. When the global competence is selected you will se both the favourite competece and the new company competence.':
    'You have selected a competence from the global library and that competence is marked as favorite in your company. When the global competence is selected you will se both the favourite competece and the new company competence.',
  'Show competences and employees': 'Show competences and employees',
  'Only employees': 'Only employees',
  'Only competences': 'Only competences',
  'New update exists': 'New update exists',
  'Your version of Kompetensvy is not correct and the application may not work as intended.':
    'Your version of Kompetensvy is not correct and the application may not work as intended.',
  'Try reloading the page, clear the cache or call support for help.':
    'Try reloading the page, clear the cache or call support for help.',
  'Your version': 'Your version',
  'Latest version': 'Latest version',
  'Reload page': 'Reload page',
  'Choose which competences to show on the employees in the report.':
    'Choose which competences to show on the employees in the report.',
  'If you select to show missing competences, all competencens will be compared against each chosen employees even if the person has the competence or not.':
    'If you select to show missing competences, all competencens will be compared against each chosen employees even if the person has the competence or not.',
  'Choose which organisations you would like to be shown in Excel. This will make each chosen organisation a column and will show if the employee is in that organisation or not.':
    'Choose which organisations you would like to be shown in Excel. This will make each chosen organisation a column and will show if the employee is in that organisation or not.',
  'Your account was successfully removed': 'Your account was successfully removed',
  'Remove user email': 'Remove user email',
  'User email removed': 'User email removed',
  'No message sent, contact support': 'No message sent, contact support',
  'Add all company competences the chosen employees have':
    'Add all company competences the chosen employees have',
  'Select all competences the employees have as required':
    'Select all competences the employees have as required',
  'Send messages to employees': 'Send messages to employees',
  'If this is checked the message form will open after you click handle.':
    'If this is checked the message form will open after you click handle.',
  'Send reminders to people with authorizations about the state of their organisations':
    'Send reminders to people with authorizations about the state of their organisations',
  'Requirements edited': 'Requirements edited',
  'tasks must be done': 'tasks must be done',
  'gradings must be done': 'gradings must be done',
  'A grading must be done': 'A grading must be done',
  'There are connected employees in the selected organisation or beneath.':
    'There are connected employees in the selected organisation or beneath.',
  'Remove grading': 'Remove grading',
  'By pressing remove you acknowledge that this cannot be undone and you will remove the grading.':
    'By pressing remove you acknowledge that this cannot be undone and you will remove the grading.',
  'Following employees organisations cannot be removed, because if selected organisations is removed from the employees the employee will not be visible for you.':
    'Following employees organisations cannot be removed, because if selected organisations is removed from the employees the employee will not be visible for you.',
  'Technical issue, try again later': 'Technical issue, try again later',
  'BankID_outstandingTransaction': 'Start your BankID app.',
  'BankID_noClient': 'Start your BankID app.',
  'BankID_userSign': 'Enter your security code in the BankID app and select Identify or Sign.',
  'BankID_started': 'Searching for BankID, it may take a little while … If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank. If you have a BankID on another device you can start the BankID app on that device.',
  'BankID_expiredTransaction': 'The BankID app is not responding. Please check that it’s started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again.',
  'BankID_Cancel': 'Action cancelled.',
  'BankID_userCancel': 'Action cancelled.',
  'BankID_cancelled': 'Action cancelled. Please try again.',
  'BankID_startFailed': 'The BankID app couldn’t be found on your computer or mobile device. Please install it and get a BankID from your bank. Install the app from your app store or https://install.bankid.com',
  'BankID_userExists': 'This social security number is already being used on another account.',
  'BankID_cantConnectUser': 'Something went wrong when you tried to connect BankID to your account. Please try again.',
  'BankID_missingUser': "We couldn't find an account with your social security number in Kompetensvy. Please sign in with your email or create an account if you don't have one.",
  'BankID_certificateErr': 'The BankID you are trying to use is blocked or too old. Please use another BankID or get a new one from your bank.',
  'BankID_userMrtd': 'Process your machine-readable travel document using the BankID app.',
  'Allow task file upload': 'Allow task file upload',
  'Task file upload is required': 'Task file upload is required',
  'Upload your certificate': 'Upload your certificate',
  'Uploaded certificate': 'Uploaded certificate',
  'Uploaded certificates': 'Uploaded certificates',
  'At least one file is required': 'At least one file is required'
};

export default translation;
