import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Avatar, Box, Divider, Drawer, Link, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import WorkIcon from '@mui/icons-material/Work';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import PersonIcon from '@mui/icons-material/Person';
import WcIcon from '@mui/icons-material/Wc';
import CategoryIcon from '@mui/icons-material/Category';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import useCompany from '../../hooks/useCompany';
import useReseller from '../../mutations/use-reseller';
import Logo from '../logo.component';
import NavSection from '../nav-section.component';
import Scrollbar from '../scrollbar.component';
import SelectCompany from './select-company.component';
import { selectedResellerVar } from '../../apollo-client';
import { ROLES, ROLE_ACCESS } from '../../constants';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import fileUrl from '../../utils/file-url';

interface SidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { company, companies, selectedCompany, setCompany } = useCompany();
  const { selectReseller } = useReseller();
  const resellerId = useReactiveVar(selectedResellerVar);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { t } = useTranslation();

  const [getResellerData, { data: resellerData }] = useLazyQuery(gql`
    query GetData {
      resellers {
        id
        name
        image {
          dataId
        }
      }
    }
  `);

  // execute query on component mount
  useEffect(() => {
    if (user?.roles.includes(ROLES.RESELLER_ADMIN)) {
      getResellerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!user) {
    return null;
  }

  const userItems: any[] = [
    {
      title: t('Dashboard'),
      path: '/',
      icon: <HomeIcon fontSize="small" />,
      matchChildren: false,
    },
    {
      title: t('Competences'),
      path: '/competences',
      icon: <CardMembershipIcon fontSize="small" />,
      matchChildren: true,
    },
  ];

  if (user.employments.length) {
    userItems.push({
      title: t('Employments'),
      path: '/employments',
      icon: <WorkIcon fontSize="small" />,
      matchChildren: true,
      matchChildPaths: ['/employment'],
    });
  }

  if (user.userPermissions.filter((x: any) => x.accepted).length) {
    userItems.push({
      title: t('Permissions'),
      path: '/permissions',
      icon: <ArtTrackIcon fontSize="small" />,
      matchChildren: true,
    });
  }

  userItems.push({
    title: t('Account'),
    path: '/account',
    icon: <PersonIcon fontSize="small" />,
    matchChildren: false,
  });

  const sections: any[] = [
    {
      title: user.name,
      path: '/account',
      icon: user.avatar,
      items: userItems,
    },
  ];

  if (company) {
    const isOnlyManager = company.role.role === ROLES.COMPANY_MANAGER;
    const isOnlyExecutive = company.role.role === ROLES.COMPANY_EXECUTIVE;
    const isAdmin = !isOnlyManager && !isOnlyExecutive;

    let managerCanSeeLibrary = false;
    let managerCanSeeOrganisations = false;
    if (isOnlyManager) {
      if (
        company.role.access.find(
          (x: any) => x === ROLE_ACCESS.ADMINISTRATE_LIBRARY,
        )
      ) {
        managerCanSeeLibrary = true;
      }

      if (
        company.role.access.find(
          (x: any) => x === ROLE_ACCESS.ADMINISTRATE_ORGANISATIONS,
        )
      ) {
        managerCanSeeOrganisations = true;
      }
    }

    const companyItems: any[] = [
      {
        title: t('Dashboard'),
        path: '/company-dashboard',
        icon: <HomeIcon fontSize="small" />,
        matchChildren: false,
      },
      {
        title: t('Employees'),
        path: '/company-employments',
        icon: <WcIcon fontSize="small" />,
        matchChildren: true,
      },
    ];

    if (isAdmin || managerCanSeeOrganisations) {
      companyItems.push({
        title: t('Organisation'),
        path: '/company-organisation',
        icon: <CategoryIcon fontSize="small" />,
        matchChildren: true,
      });
    }

    if (isAdmin || managerCanSeeLibrary) {
      if (company.useTags) {
        const libraryItemChildrens = [
          {
            title: t('Competences'),
            path: '/company-library',
          },
          {
            title: t('Tags'),
            path: '/company-tags',
          },
        ];

        companyItems.push({
          title: t('Library'),
          path: '/company-library',
          matchChildren: true,
          icon: <ChromeReaderModeIcon fontSize="small" />,
          children: libraryItemChildrens,
        });
      } else {
        companyItems.push({
          title: t('Library'),
          path: '/company-library',
          icon: <ChromeReaderModeIcon fontSize="small" />,
          matchChildren: true,
        });
      }
    }

    if (company.usePermissions) {
      companyItems.push({
        title: t('Work/driving permissions'),
        path: '/company-permissions',
        icon: <ArtTrackIcon fontSize="small" />,
        matchChildren: true,
        children: [
          {
            title: t('Templates work/driving permissions'),
            path: '/company-permissions'
          },
          {
            title: t('Missing work/driving permissions'),
            path: '/company-permissions/missing',
          }
        ],
      });
    }

    if (company.useManagers && isAdmin) {
      companyItems.push({
        title: t('Authorizations'),
        path: '/company-authorizations',
        icon: <SupervisorAccountIcon fontSize="small" />,
        matchChildren: true,
      });
    }

    if (company.useExecutives && isAdmin) {
      companyItems.push({
        title: t('Executives'),
        path: '/company-executives',
        icon: <SupervisorAccountIcon fontSize="small" />,
        matchChildren: true,
      });
    }

    if (company.useCourseInstances && isAdmin) {
      companyItems.push({
        title: t('Course instances'),
        path: '/company-course-instances',
        icon: <AllInboxIcon fontSize="small" />,
        matchChildren: true,
      });
    }

    const reportItemChildrens = [
      {
        title: t('Requirements'),
        path: '/company-reports/requirements',
      },
      {
        title: t('Library'),
        path: '/company-reports/library',
      },
      {
        title: t('Others'),
        path: '/company-reports/others',
      },
    ];

    if (company.usePermissions) {
      reportItemChildrens.push({
        title: t('Work/driving permissions'),
        path: '/company-reports/permissions',
      });
    }

    companyItems.push({
      title: t('Report'),
      path: '/company-reports',
      matchChildren: true,
      icon: <FindInPageIcon fontSize="small" />,
      children: reportItemChildrens,
    });

    if (isAdmin) {
      const settingsItemChildrens = [
        {
          title: t('General'),
          path: '/company-settings',
        },
        {
          title: t('Due date'),
          path: '/company-settings/expiring-warning',
        },
        {
          title: t('Reminders'),
          path: '/company-settings/reminders',
        },
        {
          title: t('Files'),
          path: '/company-files',
        },
      ];

      if (company.companyApiConnections.length) {
        settingsItemChildrens.push({
          title: t('Api connections'),
          path: '/company-settings/api-connections',
        });
      }

      companyItems.push({
        title: t('Settings'),
        path: '/company-settings',
        matchChildren: true,
        icon: <SettingsIcon fontSize="small" />,
        children: settingsItemChildrens,
      });
    }

    sections.push({
      title: company.name,
      subtitle: (
        <Typography color="textSecondary" variant="body2">
          <SelectCompany
            options={companies}
            value={selectedCompany}
            buttonComponent={Link}
            buttonText={t('Change company')}
            onSelect={(newCompany) => {
              navigate('/company-dashboard');
              setCompany(newCompany.id);
            }}
          />
        </Typography>
      ),
      path: '/company-settings',
      icon: company.avatar,
      items: companyItems,
    });
  }

  if (
    user.roles.includes(ROLES.RESELLER_ADMIN) &&
    resellerData?.resellers.length
  ) {
    let reseller = resellerData.resellers[0];
    if (resellerId) {
      reseller = resellerData.resellers.find((x: any) => x.id === resellerId);
    } else {
      selectReseller(reseller.id);
    }

    let image = '';
    if (reseller.image) {
      image = fileUrl(reseller.image.dataId);
    }

    sections.push({
      title: reseller.name,
      subtitle: resellerData.resellers.length > 1 && (
        <Typography color="textSecondary" variant="body2">
          <SelectCompany
            options={resellerData.resellers}
            value={reseller}
            buttonComponent={Link}
            buttonText={t('Change reseller company')}
            onSelect={(newReseller) => {
              navigate('/');
              selectReseller(newReseller.id);
            }}
          />
        </Typography>
      ),
      path: '/reseller-companies',
      icon: image,
      items: [
        {
          title: t('Companies'),
          path: '/reseller-companies',
          icon: <BusinessIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Import employees'),
          path: '/import-employees',
          icon: <ImportExportIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Import certificates'),
          path: '/import-certificates',
          icon: <ImportExportIcon fontSize="small" />,
          matchChildren: true,
        },
      ],
    });
  }

  if (user.roles.includes(ROLES.SUPER_ADMIN)) {
    sections.push({
      title: t('Administration'),
      path: '/admin-invoices',
      icon: <AdminPanelSettingsIcon />,
      items: [
        {
          title: t('Invoices'),
          path: '/admin-invoices',
          icon: <ReceiptIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Calculate cost'),
          path: '/admin-calculate-cost',
          icon: <AttachMoneyIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Companies'),
          path: '/admin-companies',
          icon: <BusinessIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Resellers'),
          path: '/admin-resellers',
          icon: <StorefrontIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Users'),
          path: '/admin-users',
          icon: <PeopleIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Competences'),
          path: '/admin-competences',
          icon: <CardMembershipIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: `ID06 ${t('Competences')}`,
          path: '/admin-id06-competences',
          icon: <CardMembershipIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Machine types'),
          path: '/admin-machine-types',
          icon: <LocalShippingIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Import employees'),
          path: '/import-employees',
          icon: <ImportExportIcon fontSize="small" />,
          matchChildren: true,
        },
        {
          title: t('Import certificates'),
          path: '/import-certificates',
          icon: <ImportExportIcon fontSize="small" />,
          matchChildren: true,
        },
      ],
    });
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}>
          <RouterLink to="/">
            <Logo
              lightBackground
              small
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        {sections.map((section) => (
          <Box key={section.title}>
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  display: 'flex',
                  overflow: 'hidden',
                  p: 2,
                }}>
                <RouterLink to={section.path}>
                  {_.isString(section.icon) || !section.icon ? (
                    <Avatar
                      src={section.icon}
                      sx={{
                        cursor: 'pointer',
                        height: 48,
                        width: 48,
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        cursor: 'pointer',
                        height: 48,
                        width: 48,
                      }}>
                      {section.icon}
                    </Avatar>
                  )}
                </RouterLink>
                <Box sx={{ ml: 2 }}>
                  <Typography color="textPrimary" variant="subtitle2">
                    {section.title}
                  </Typography>
                  {section.subtitle}
                </Box>
              </Box>
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3,
                  },
                }}
                {...section}
              />
            </Box>
            <Divider />
          </Box>
        ))}
        {/*<Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {t('Need help')}?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {t('Check our documentation')}
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained">
            {t('Documentation')}
          </Button>
              </Box>*/}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
      style={{ zIndex: 1301 }}>
      {content}
    </Drawer>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
};

export default Sidebar;
